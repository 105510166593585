exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-chin-people-tsx": () => import("./../../../src/pages/about_chin_people.tsx" /* webpackChunkName: "component---src-pages-about-chin-people-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-chapter-tsx": () => import("./../../../src/pages/chapter.tsx" /* webpackChunkName: "component---src-pages-chapter-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-convention-2023-tsx": () => import("./../../../src/pages/convention_2023.tsx" /* webpackChunkName: "component---src-pages-convention-2023-tsx" */),
  "component---src-pages-conventions-tsx": () => import("./../../../src/pages/conventions.tsx" /* webpackChunkName: "component---src-pages-conventions-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-get-involved-tsx": () => import("./../../../src/pages/get_involved.tsx" /* webpackChunkName: "component---src-pages-get-involved-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-programs-chin-voices-of-next-gen-tsx": () => import("./../../../src/pages/programs/chin_voices_of_next_gen.tsx" /* webpackChunkName: "component---src-pages-programs-chin-voices-of-next-gen-tsx" */),
  "component---src-pages-programs-tsx": () => import("./../../../src/pages/programs.tsx" /* webpackChunkName: "component---src-pages-programs-tsx" */),
  "component---src-pages-special-guests-tsx": () => import("./../../../src/pages/special_guests.tsx" /* webpackChunkName: "component---src-pages-special-guests-tsx" */)
}

